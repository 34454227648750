// タイプ0       タイプ1       タイプ2       タイプ3
//   ---+------    ----+-----    ----+-----    ----------
//   |  |     |    |   |    |    |   |    |    |        |
//   |A |     |    |   |B   |    |A  |B   |    |A       |
//   +--+     |    |   +----+    +---+----+    +---+----+
//   |  |     |    |   |    |    |        |    |   |    |
//   |C |B    |    |A  |C   |    |C       |    |B  |C   |
//   ---+------    ----+-----    ----------    ----------
//
// タイプ4        タイプ5       タイプ6
//    ----------    ----+-----    ----------
//    |        |    |   |    |    |        |
//    |A       |    |   |    |    |        |
//    +--------+    |   |    |    |        |
//    |        |    |   |    |    |        |
//    |B       |    |A  |B   |    |A       |
//    ----------    ----------    ----------

class Content {
  constructor(item) {
    this.item = item;
    this.aspectRatio = this.item.offsetHeight / this.item.offsetWidth;
    this.originalWidth = item.offsetWidth;
    this.originalHeight = item.offsetHeight;
    if (this.aspectRatio < 0.6) {
      this.shape = 'wide';
    } else if (this.aspectRatio > 1.4) {
      this.shape = 'tall';
    } else {
      this.shape = 'middle';
    }
  }

  getAspectRatio() {
    return this.aspectRatio;
  }

  getShape() {
    return this.shape;
  }

  size() {
    // console.log((this.item.getElementsByTagName('img')[0].height / this.item.getElementsByTagName('img')[0].width));
    return {x: this.originalWidth, y: this.originalHeight};
  }

  draw(x, y, width, height) {
    this.item.style.position = 'absolute';
    this.item.style.left = `${Math.floor(x)}px`;
    this.item.style.top = `${Math.floor(y)}px`;
    this.item.style.width = `${Math.floor(width)}px`;
    this.item.style.height = `${Math.floor(height)}px`;
  }
}

class Panel {
  constructor(items) {
    this.contents = items.slice(0, 3).map(item => new Content(item))
                                          .sort((a, b) => {
                                            if (a.getAspectRatio() < b.getAspectRatio()) {
                                              return -1;
                                            } else {
                                              return 1;
                                            }
                                          });
    if (this.contents.length === 1) {
      this.type = 6;
    } else if (this.contents.length === 2) {
      this.twinTypeEstablish()
    } else if (this.contents.length > 2) {
      this.tripleTypeEstablish()
    }
  }

  twinTypeEstablish() {
    // if (this.contents.every(content => content.getAspectRatio() === 'wide')) {
    //   this.type = 4;
    // } else if (this.contents.every(content => content.getShape() === 'tall')) {
      this.type = 5;
    // } else {
    //   const typeSuggestion = [4, 5]
    //   this.type = typeSuggestion[Math.floor(Math.random() * 2)]
    // }
  }

  tripleTypeEstablish() {
    const shapeCount = {
      'tall': 0,
      'middle': 0,
      'wide': 0,
    }
    for (const content of this.contents) {
      shapeCount[content.getShape()]++;
    }
    // if (shapeCount.wide === 2) {
      const typeSuggestion = [0, 1]
      this.type = typeSuggestion[Math.floor(Math.random() * 2)]
    // } else {
    //   const typeSuggestion = [2, 3]
    //   this.type = typeSuggestion[Math.floor(Math.random() * 2)]
    // }
  }

  draw(x, y, panelWidth) {
    this.width = panelWidth;
    switch (this.type) {
      case 0:
        return this.draw0(x, y);
      case 1:
        return this.draw1(x, y);
      case 2:
        return this.draw2(x, y);
      case 3:
        return this.draw3(x, y);
      case 4:
        return this.draw4(x, y);
      case 5:
        return this.draw5(x, y);
      case 6:
        return this.draw6(x, y);
      case 6:
        return this.draw6(x, y);
      default:
        return [0, 0]
    }
  }

  draw0(x, y) {
    if (this.contentA === undefined) {
      [ this.contentA, this.contentC ] = (() => {
        if (Math.random() > 0.5) {
          return [this.contents[0], this.contents[1]];
        } else {
          return [this.contents[1], this.contents[0]];
        }
      })();
      this.contentB = this.contents[2];
    }

    let sizeA = this.contentA.size();
    let sizeB = this.contentB.size();
    let sizeC = this.contentC.size();

    sizeC = {
      x: sizeC.x * (sizeA.x / sizeC.x),
      y: sizeC.y * (sizeA.x / sizeC.x)
    }
    sizeB = {
      x: sizeB.x * ((sizeA.y + sizeC.y) / sizeB.y),
      y: sizeB.y * ((sizeA.y + sizeC.y) / sizeB.y)
    }

    const ratio = this.width / (sizeA.x + sizeB.x);

    sizeA = {x: sizeA.x * ratio, y: sizeA.y * ratio};
    sizeB = {x: sizeB.x * ratio, y: sizeB.y * ratio};
    sizeC = {x: sizeC.x * ratio, y: sizeC.y * ratio};

    this.contentA.draw(x, y, sizeA.x, sizeA.y);
    this.contentB.draw(x + sizeA.x, y, sizeB.x, sizeB.y);
    this.contentC.draw(x, y + sizeA.y, sizeC.x, sizeC.y);
    return [this.width, sizeB.y];
  }

  draw1(x, y) {
    if (this.contentA === undefined) {
      [ this.contentB, this.contentC ] = (() => {
        if (Math.random() > 0.5) {
          return [this.contents[0], this.contents[1]];
        } else {
          return [this.contents[1], this.contents[0]];
        }
      })();
      this.contentA = this.contents[2];
    }

    let sizeA = this.contentA.size();
    let sizeB = this.contentB.size();
    let sizeC = this.contentC.size();

    sizeC = {
      x: sizeC.x * (sizeB.x / sizeC.x),
      y: sizeC.y * (sizeB.x / sizeC.x)
    }
    sizeA = {
      x: sizeA.x * ((sizeB.y + sizeC.y) / sizeA.y),
      y: sizeA.y * ((sizeB.y + sizeC.y) / sizeA.y)
    }

    const ratio = this.width / (sizeA.x + sizeB.x);

    sizeA = {x: sizeA.x * ratio, y: sizeA.y * ratio};
    sizeB = {x: sizeB.x * ratio, y: sizeB.y * ratio};
    sizeC = {x: sizeC.x * ratio, y: sizeC.y * ratio};

    this.contentA.draw(x, y, sizeA.x, sizeA.y);
    this.contentB.draw(x + sizeA.x, y, sizeB.x, sizeB.y);
    this.contentC.draw(x + sizeA.x, y + sizeB.y, sizeC.x, sizeC.y);

    return [this.width, sizeA.y];
  }

  draw2(x, y) {
    if (this.contentA === undefined) {
      [ this.contentA, this.contentB ] = (() => {
        if (Math.random() > 0.5) {
          return [this.contents[1], this.contents[2]];
        } else {
          return [this.contents[2], this.contents[1]];
        }
      })();
      this.contentC = this.contents[0];
    }

    let sizeA = this.contentA.size();
    let sizeB = this.contentB.size();
    let sizeC = this.contentC.size();

    sizeB = {
      x: sizeB.x * (sizeA.y / sizeB.y),
      y: sizeB.y * (sizeA.y / sizeB.y)
    }
    sizeC = {
      x: sizeC.x * ((sizeA.x + sizeB.x) / sizeC.x),
      y: sizeC.y * ((sizeA.x + sizeB.x) / sizeC.x)
    }

    const ratio = this.width / (sizeA.x + sizeB.x);

    sizeA = {x: sizeA.x * ratio, y: sizeA.y * ratio};
    sizeB = {x: sizeB.x * ratio, y: sizeB.y * ratio};
    sizeC = {x: sizeC.x * ratio, y: sizeC.y * ratio};

    this.contentA.draw(x, y, sizeA.x, sizeA.y);
    this.contentB.draw(x + sizeA.x, y, sizeB.x, sizeB.y);
    this.contentC.draw(x, y + sizeA.y, sizeC.x, sizeC.y);

    return [this.width, sizeA.y + sizeC.y];
  }

  draw3(x, y) {
    if (this.contentA === undefined) {
      [ this.contentB, this.contentC ] = (() => {
        if (Math.random() > 0.5) {
          return [this.contents[1], this.contents[2]];
        } else {
          return [this.contents[2], this.contents[1]];
        }
      })();
      this.contentA = this.contents[0];
    }

    let sizeA = this.contentA.size();
    let sizeB = this.contentB.size();
    let sizeC = this.contentC.size();

    sizeC = {
      x: sizeC.x * (sizeB.y / sizeC.y),
      y: sizeC.y * (sizeB.y / sizeC.y)
    }
    sizeA = {
      x: sizeA.x * ((sizeC.x + sizeB.x) / sizeA.x),
      y: sizeA.y * ((sizeC.x + sizeB.x) / sizeA.x)
    }

    const ratio = this.width / (sizeA.x);

    sizeA = {x: sizeA.x * ratio, y: sizeA.y * ratio};
    sizeB = {x: sizeB.x * ratio, y: sizeB.y * ratio};
    sizeC = {x: sizeC.x * ratio, y: sizeC.y * ratio};

    this.contentA.draw(x, y, sizeA.x, sizeA.y);
    this.contentB.draw(x, y + sizeA.y, sizeB.x, sizeB.y);
    this.contentC.draw(x + sizeB.x, y + sizeA.y, sizeC.x, sizeC.y);

    return [this.width, sizeA.y + sizeB.y];
  }

  draw4(x, y) {
    if (this.contentA === undefined) {
      [this.contentA, this.contentB] = this.contents;
    }

    let sizeA = this.contentA.size();
    let sizeB = this.contentB.size();

    sizeB = {
      x: sizeB.x * (sizeA.x / sizeB.x),
      y: sizeB.y * (sizeA.x / sizeB.x)
    }

    const ratio = this.width / (sizeA.x);

    sizeA = {x: sizeA.x * ratio, y: sizeA.y * ratio};
    sizeB = {x: sizeB.x * ratio, y: sizeB.y * ratio};

    this.contentA.draw(x, y, sizeA.x, sizeA.y);
    this.contentB.draw(x, y + sizeA.y, sizeB.x, sizeB.y);

    return [this.width, sizeA.y + sizeB.y];
  }

  draw5(x, y) {
    if (this.contentA === undefined) {
      [this.contentA, this.contentB] = this.contents;
    }

    let sizeA = this.contentA.size();
    let sizeB = this.contentB.size();

    sizeB = {
      x: sizeB.x * (sizeA.y / sizeB.y),
      y: sizeB.y * (sizeA.y / sizeB.y)
    }

    const ratio = this.width / (sizeA.x + sizeB.x);

    sizeA = {x: sizeA.x * ratio, y: sizeA.y * ratio};
    sizeB = {x: sizeB.x * ratio, y: sizeB.y * ratio};

    this.contentA.draw(x, y, sizeA.x, sizeA.y);
    this.contentB.draw(x + sizeA.x, y, sizeB.x, sizeB.y);

    return [this.width, sizeA.y];
  }

  draw6(x, y) {
    if (this.contentA === undefined) {
      this.contentA = this.contents[0];
    }

    let sizeA = this.contentA.size();

    const ratio = this.width / (sizeA.x);

    sizeA = {x: sizeA.x * ratio, y: sizeA.y * ratio};

    this.contentA.draw(x, y, sizeA.x, sizeA.y);

    return [sizeA.x, sizeA.y]
  }
}

function makePanels(itemList, itemSelector) {
  let items = [];
  const panels = [];
  Array.from(itemList.getElementsByClassName(itemSelector)).forEach((item,
                                                                itemIndex,
                                                                itemElements) => {
    items.push(item);
    if (items.length > 2) {
      panels.push(new Panel(items));
      items = [];
    }
  });
  if (items.length > 0) {
    panels.push(new Panel(items));
    items = [];
  }
  return panels;
}

function tetoDraw(targetBoards, maxWidth) {
  for (const board of targetBoards) {
    const isOneLine = board.holder.offsetWidth < maxWidth;
    let nextYs = [0, 0];
    const panelWidth = isOneLine ? board.holder.offsetWidth : Math.floor(board.holder.offsetWidth / 2);
    for (const panel of board.panels) {
      const yIndex = (() => {
        if (isOneLine) {
          return 0;
        } else if (nextYs[0] <= nextYs[1]) {
          return 0;
        } else {
          return 1;
        }
      })();
      const [_, panelHeight] = panel.draw(yIndex * panelWidth, nextYs[yIndex], panelWidth);
      nextYs[yIndex] += panelHeight;
    }
    board.holder.style.position = 'relative';
    board.holder.style.width = '100%'
    board.holder.style.height = `${Math.max(...nextYs)}px`
  }
}

function teto({targetItemHolder, itemSelector, maxWidth}) {
  const targetBoards = [];
  Array.from(document.getElementsByClassName(targetItemHolder)).forEach((list,
                                                                    listIndex,
                                                                    listElements) => {
    targetBoards.push({holder: list, panels: makePanels(list, itemSelector)});
  });
  tetoDraw(targetBoards, maxWidth);
  window.addEventListener("resize", () => tetoDraw(targetBoards, maxWidth));
}

window.teto = teto;
